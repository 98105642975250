import { createRouter, createWebHistory  } from 'vue-router'
import Cookies from "js-cookie";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';


const router = createRouter({
  history: createWebHistory(),
  routes:[
    {
      path: '/',
      name: 'login',
      component: () => import('@/login/login.vue')
    },
    { //主界面
      path: '/index',
      name: 'index',
      component: () => import('@/views/home/index.vue'),
      redirect: '/screenStatus',
      children: [
        {
          path: '/screenStatus',
          name: 'screenStatus',
          component: () => import('@/views/screenStatus/index.vue'),
        }
      ]
    },
  ]
})

router.beforeEach((to, from, next) => {
  NProgress.start();
  const tokenkey = Cookies.get('token');
  if (tokenkey) {
    next();
  } else{
    if(to.path == '/'){   //判断是否已经进入登录页面path  防止死循环
       next()
    }else{
      next('/')
    }
  }
});

router.afterEach((to,from,next)=>{
  NProgress.done()   //进度条结束
})



export default router
